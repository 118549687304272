/**
 * @license
 * Copyright 2021 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var _a;
import { onChildAdded, onChildChanged, onChildMoved, onChildRemoved, onValue } from 'firebase/database';
export var ListenEvent;
(function (ListenEvent) {
    ListenEvent["added"] = "child_added";
    ListenEvent["removed"] = "child_removed";
    ListenEvent["changed"] = "child_changed";
    ListenEvent["moved"] = "child_moved";
    ListenEvent["value"] = "value";
})(ListenEvent || (ListenEvent = {}));
export var ListenerMethods = Object.freeze((_a = {},
    _a[ListenEvent.added] = onChildAdded,
    _a[ListenEvent.removed] = onChildRemoved,
    _a[ListenEvent.changed] = onChildChanged,
    _a[ListenEvent.moved] = onChildMoved,
    _a[ListenEvent.value] = onValue,
    _a));
